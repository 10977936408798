<template>
  <div class="">
    <nav-bar />
    <div :class="[isLoading ? 'preload' : 'hidden']">
      <div class="loading"></div>
    </div>
    <div class="container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import NavBar from "@/components/NavBar.vue";

const isLoading = ref(true);
onBeforeMount(() => {
  setTimeout(() => {
    isLoading.value = false;
  }, 3000);
});
</script>

<style>
@import "./fonts/fontawesome.min.css";
/* @import "./fonts/monzerat.woff2"; */
:root {
  --pc: #6e43b6;
  --sc: #a4bebb;
  --tc: #808c40;
  --fc: grey;
  --sw: 4px 0 20px -8px grey;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;

  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none;
  scroll-behavior: smooth;
}
@keyframes scaler {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  font-style: bold;
  color: var(--tc);
}
p {
  font-size: 1rem;
  letter-spacing: 2px;
  color: var(--tc);
  line-height: 1.6rem;
  font-weight: 300;
  padding-top: 0;
  font-style: oblique;
  text-emphasis-color: grey;
  text-transform: capitalize;
  letter-spacing: 1px;
  word-spacing: 2px;
  text-align: left;
  padding-top: 20px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}
span {
  color: var(--pc);
}
html::-webkit-scrollbar {
  scrollbar-width: none;
}
.router-link-exact-active {
  color: var(--tc);
}
.container {
  margin-left: 0;
  width: 100%;
  overflow: hidden;
}
.hidden {
  display: none;
}
.preload {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: white;
  backdrop-filter: blur(20px);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.loading {
  height: 100px;
  width: 100px;
  background: url("./assets/loading.gif");

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 999999;
}
</style>
