<template>
  <nav>
    <div class="log"></div>
    <div class="nav-box">
      <router-link class="nav-link" to="/">
        <i class="fa fa-home link-icon"></i>
        <span class="link-name">Home</span>
      </router-link>
    </div>
    <div class="nav-box">
      <router-link to="/portfolio" class="nav-link">
        <i class="fa fa-magic" aria-hidden="true"></i>
        <span class="link-name">Portfolio</span></router-link
      >
    </div>
    <div class="nav-box">
      <router-link to="/about" class="nav-link">
        <i class="fa fa-user link-icon"></i>
        <span class="link-name">About</span></router-link
      >
    </div>
    <div class="nav-box">
      <router-link to="/contact" class="nav-link">
        <i class="fa fa-phone link-icon"></i>
        <span class="link-name">Contact</span></router-link
      >
    </div>
  </nav>
</template>
<script>
export default {
  name: "NavBarView",
};
</script>

<style scoped>
nav {
  position: fixed;
  background-color: white;
  height: 100vh;
  width: auto;
  left: 0;
  top: 0;
  border-radius: 0px;
  box-shadow: var(--sw);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 100px;
  gap: 20px;
}
.nav-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
@media (width<748px) {
  .nav-box {
    padding: 10px;
  }
}
/* nav:hover .link-name {
  display: block;
  padding-left: 5px;
} */
.nav-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-style: bold;

  color: var(--pc);
}
.link-name {
  display: none;
  font-size: 0.8rem;
}
</style>
